export default function LogoText(props) {
    return (
        <svg
            viewBox="0 0 222 50"
            fill="none"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.5527 35.3954C37.1888 35.3954 45.0004 27.5838 45.0004 17.9477C45.0004 8.3116 37.1888 0.5 27.5527 0.5C17.9166 0.5 10.105 8.3116 10.105 17.9477C10.105 27.5838 17.9166 35.3954 27.5527 35.3954Z"
                fill="#EF4130"
            />
            <path
                d="M30.9018 9.8584H24.2076V13.3092H30.9018V9.8584Z"
                fill="white"
            />
            <path
                d="M30.9015 9.8584H27.5432V13.3092H30.9015V9.8584Z"
                fill="white"
            />
            <path
                d="M31.8694 15.2817H23.236V18.7325H31.8694V15.2817Z"
                fill="white"
            />
            <path
                d="M31.8694 15.2817H27.5432V18.7325H31.8694V15.2817Z"
                fill="white"
            />
            <path
                d="M34.4542 20.2107H20.6511V28.837H34.4542V20.2107Z"
                fill="white"
            />
            <path
                d="M34.4545 20.2107H27.5432V28.837H34.4545V20.2107Z"
                fill="white"
            />
            <path
                d="M30.9013 13.2227H24.2071C23.0398 13.7935 21.5008 13.9788 19.473 13.322C19.473 14.5969 20.5065 15.6305 21.7814 15.6305H33.3239C34.5988 15.6305 35.6324 14.5969 35.6324 13.322C33.6047 13.9788 32.0686 13.7936 30.9013 13.2227Z"
                fill="white"
            />
            <path
                d="M30.9012 13.2227H27.5429V15.6306H33.3239C34.5988 15.6306 35.6324 14.597 35.6324 13.3221C33.6046 13.9788 32.0685 13.7936 30.9012 13.2227Z"
                fill="white"
            />
            <path
                d="M31.8692 18.4629H23.2363C21.7255 19.2017 19.7203 19.4415 17.0958 18.5914C17.0958 20.2415 18.4335 21.579 20.0835 21.5791H35.022C36.6721 21.5791 38.0097 20.2415 38.0097 18.5914C35.3852 19.4416 33.3799 19.2018 31.8692 18.4629Z"
                fill="white"
            />
            <path
                d="M31.8691 18.4629H27.5429V21.5791H35.0218C36.6719 21.5791 38.0095 20.2415 38.0095 18.5914C35.3851 19.4416 33.3798 19.2018 31.8691 18.4629Z"
                fill="white"
            />
            <path
                d="M21.3043 9.21846C26.3985 10.8684 27.5526 5.64795 27.5526 5.64795C27.5526 5.64795 28.7066 10.8684 33.8009 9.21846C33.8009 10.2045 33.0016 11.0036 32.0157 11.0036H23.0896C22.1036 11.0036 21.3043 10.2045 21.3043 9.21846Z"
                fill="white"
            />
            <path
                d="M27.5527 5.64795C27.5527 5.64795 27.5494 5.66253 27.5429 5.6885V11.0036H32.0157C33.0017 11.0036 33.8009 10.2043 33.8009 9.21846C28.7068 10.8684 27.5527 5.64795 27.5527 5.64795Z"
                fill="white"
            />
            <path
                d="M31.0037 23.6614H27.5432V28.8369H31.0037V23.6614Z"
                fill="white"
            />
            <path
                d="M31.0034 23.6614H24.1019V28.8369H31.0034V23.6614Z"
                fill="#EF4130"
            />
            <path
                d="M71.3189 6.25415L71.1918 10.3552C68.7591 10.1647 66.4685 10.0681 64.3149 10.0681C64.29 12.4714 64.2775 15.1469 64.2775 18.0971C64.2775 22.6734 64.3448 27.0047 64.4769 31.086H59.3722C59.5293 27.0418 59.609 22.713 59.609 18.0971C59.609 15.1988 59.5841 12.5209 59.5367 10.0681C57.8219 10.0681 55.4689 10.1696 52.4779 10.3726L52.625 6.25415H71.3189Z"
                fill="#EF4130"
            />
            <path
                d="M87.6923 31.0859H82.8768C83.0338 28.8361 83.1136 26.3116 83.1136 23.5148C83.1136 22.3837 83.1809 20.9779 83.313 19.2999H83.0388C82.7222 20.2528 81.2766 22.2996 78.6968 25.4404C76.3139 28.3386 75.1225 30.2221 75.1225 31.0859H71.3513C71.5083 28.3139 71.5881 25.2944 71.5881 22.0273C71.5881 18.352 71.3812 15.3894 70.9675 13.1396L76.1469 12.5876C76.0024 15.028 75.9276 17.4585 75.9276 19.8741C75.9276 21.1462 75.8603 22.7797 75.7282 24.7746H76.0024C76.2342 23.9727 77.6125 21.9902 80.1425 18.8247C82.5253 15.8373 83.7167 13.9415 83.7167 13.142L87.6749 12.5901C87.5278 15.0305 87.4555 18.1787 87.4555 22.0298C87.4555 25.079 87.5353 28.0986 87.6923 31.0859Z"
                fill="#EF4130"
            />
            <path
                d="M108.39 21.9705C108.39 25.1484 107.433 27.5863 105.519 29.2841C103.605 30.982 101.302 31.8309 98.6146 31.8309C95.3793 31.8309 93.1236 30.8038 91.8474 28.752C90.5712 26.6977 89.9331 23.3763 89.9331 18.7876C89.9331 16.3844 90.1699 14.3375 90.6435 12.6471C91.1171 10.9567 91.8499 9.55828 92.8419 8.45195C93.8339 7.34562 95.0877 6.49669 96.6081 5.90516C98.1286 5.31363 99.9332 4.91021 102.024 4.69488C102.68 4.63053 103.395 4.534 104.168 4.40035C104.941 4.2667 105.838 4.13058 106.857 3.9895L106.638 8.26137C106.321 8.2985 105.726 8.37522 104.851 8.48907C103.976 8.60292 102.874 8.74895 101.551 8.92715C100.419 9.0806 99.4072 9.28108 98.5149 9.52858C97.6201 9.77608 96.8599 10.1473 96.2342 10.6448C95.6086 11.1398 95.1226 11.8031 94.7761 12.6273C94.4297 13.4539 94.2327 14.5281 94.1829 15.8498H94.3648C95.7507 14.1717 97.6226 13.3327 99.983 13.3327C102.428 13.3327 104.44 14.0331 106.02 15.4315C107.6 16.8274 108.39 19.0079 108.39 21.9705ZM103.667 22.5595C103.667 20.9062 103.276 19.5425 102.49 18.4683C101.705 17.3942 100.579 16.8571 99.1081 16.8571C97.0294 16.8571 95.4167 17.607 94.2751 19.1069C94.2876 22.515 94.6689 24.9009 95.4242 26.2696C96.1769 27.6358 97.3384 28.3189 98.9087 28.3189C100.357 28.3189 101.511 27.7719 102.373 26.6779C103.236 25.5865 103.667 24.2128 103.667 22.5595Z"
                fill="#EF4130"
            />
            <path
                d="M126.389 20.082C126.389 20.7305 126.351 21.5769 126.279 22.6189L114.534 22.8293C114.704 24.2673 115.295 25.4602 116.304 26.4156C117.314 27.3685 118.64 27.8461 120.28 27.8461C122.104 27.8461 124.043 27.559 126.097 26.9873L125.733 30.6875C124.056 31.3235 121.977 31.6403 119.494 31.6403C116.356 31.6403 113.954 30.7196 112.281 28.8757C110.609 27.0319 109.774 24.8142 109.774 22.2204C109.774 19.5128 110.566 17.164 112.154 15.1741C113.742 13.1842 115.945 12.1892 118.767 12.1892C121.344 12.1892 123.258 12.9193 124.512 14.3821C125.761 15.8423 126.389 17.7431 126.389 20.082ZM122.122 19.9088C122.122 16.9858 120.93 15.5231 118.547 15.5231C116.274 15.5231 114.923 17.0353 114.499 20.0622L122.122 19.9088Z"
                fill="#EF4130"
            />
            <path
                d="M143.054 12.9663L142.944 16.8372C141.182 16.6467 139.43 16.5501 137.692 16.5501C137.655 18.342 137.638 20.2057 137.638 22.1387C137.638 25.4057 137.722 28.3881 137.892 31.0834H132.987C133.156 28.5267 133.241 25.5468 133.241 22.1387C133.241 20.2701 133.211 18.4064 133.149 16.5501C131.386 16.5501 129.549 16.6467 127.64 16.8372L127.767 12.9663H143.054Z"
                fill="#EF4130"
            />
            <path
                d="M158.864 27.0813L158.555 30.7245C157.448 31.3358 155.948 31.6403 154.051 31.6403C150.913 31.6403 148.503 30.7245 146.82 28.893C145.135 27.0615 144.295 24.8637 144.295 22.2946C144.295 19.6241 145.177 17.2951 146.94 15.3052C148.702 13.3153 151.117 12.3203 154.18 12.3203C155.676 12.3203 157.007 12.4985 158.176 12.8549L157.555 16.7456C156.436 16.263 155.185 16.0205 153.799 16.0205C152.329 16.0205 151.167 16.565 150.315 17.6515C149.462 18.738 149.038 20.077 149.038 21.666C149.038 23.396 149.542 24.8587 150.551 26.0517C151.561 27.2471 152.989 27.8436 154.839 27.8436C155.915 27.8436 157.259 27.5887 158.864 27.0813Z"
                fill="#EF4130"
            />
            <path
                d="M176.935 31.0859H172.047C171.95 30.9077 171.803 30.5637 171.608 30.0563C170.903 27.4996 170.178 25.7622 169.43 24.839C168.682 23.9183 167.742 23.4555 166.613 23.4555C166.322 23.4555 165.92 23.4678 165.409 23.4926C165.409 25.933 165.502 28.4649 165.684 31.0835H160.796C160.978 28.5416 161.07 25.636 161.07 22.3665C161.07 18.6911 160.85 15.6097 160.414 13.1173L165.649 12.6025C165.492 14.7137 165.412 17.2952 165.412 20.3469C165.935 20.3716 166.337 20.384 166.616 20.384C168.767 20.384 170.342 18.2282 171.339 13.9192C171.411 13.6024 171.546 13.2832 171.74 12.9664H176.319C176.197 13.2584 175.985 13.8004 175.681 14.5875C174.295 18.5673 172.737 20.9013 171.013 21.5868V21.7774C172.167 22.1338 173.131 22.8763 173.904 24.0099C174.677 25.1409 175.419 26.8017 176.137 28.9871C176.352 29.6628 176.618 30.3608 176.935 31.0859Z"
                fill="#EF4130"
            />
            <path
                d="M193.747 31.0661L189.442 31.2196C189.248 30.685 189.151 30.2024 189.151 29.7692H188.914C187.575 30.9647 185.771 31.5611 183.498 31.5611C181.977 31.5611 180.716 31.1181 179.714 30.2345C178.709 29.351 178.208 28.1778 178.208 26.7151C178.208 24.7821 178.989 23.2005 180.551 21.9655C182.114 20.733 184.854 20.0771 188.767 20.0004C188.792 19.7727 188.804 19.5548 188.804 19.3519C188.804 17.9783 188.488 17.0724 187.857 16.6343C187.224 16.1963 186.062 15.976 184.373 15.976C182.536 15.976 180.743 16.2309 178.991 16.7383L179.392 13.2287C181.045 12.6322 183.38 12.3328 186.396 12.3328C188.597 12.3328 190.297 12.8154 191.494 13.7831C192.69 14.7484 193.291 16.4413 193.291 18.8569C193.291 19.3915 193.268 20.3642 193.226 21.7749C193.184 23.1857 193.161 24.1262 193.161 24.5965C193.164 27.2323 193.358 29.3881 193.747 31.0661ZM188.879 27.0987C188.807 25.381 188.769 23.901 188.769 22.656C184.622 22.656 182.55 23.7549 182.55 25.9552C182.55 27.4675 183.463 28.2248 185.287 28.2248C186.72 28.2248 187.917 27.8511 188.879 27.0987Z"
                fill="#EF4130"
            />
            <path
                d="M211.892 31.0859H207.059C207.182 28.7965 207.241 26.4329 207.241 23.9901C206.476 23.9133 205.733 23.8762 205.016 23.8762C203.715 23.8762 202.782 24.2376 202.216 24.9627C201.651 25.6879 200.858 27.7273 199.836 31.0859H195.24C196.224 27.9971 197.054 25.8958 197.73 24.7821C198.405 23.6708 199.21 23.005 200.148 22.7897V22.5026C197.498 21.8541 196.172 20.3295 196.172 17.9263C196.172 16.1591 196.768 14.8474 197.959 13.9885C199.151 13.1297 200.713 12.7015 202.648 12.7015C204.215 12.7015 206.229 12.7782 208.685 12.9292L211.895 12.5679C211.738 15.4166 211.658 18.5624 211.658 22.0076C211.653 25.8339 211.733 28.8609 211.892 31.0859ZM207.241 20.8245C207.241 18.993 207.204 17.3917 207.132 16.018C205.793 15.9042 204.487 15.8473 203.211 15.8473C201.484 15.8473 200.621 16.6294 200.621 18.1936C200.621 19.9979 201.905 20.9012 204.47 20.9012C205.15 20.9012 206.072 20.8765 207.241 20.8245Z"
                fill="#EF4130"
            />
        </svg>
    );
}
