export default function Pagada(props) {
    return <svg {...props} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5527 35.3954C27.1888 35.3954 35.0004 27.5838 35.0004 17.9477C35.0004 8.3116 27.1888 0.5 17.5527 0.5C7.91658 0.5 0.10498 8.3116 0.10498 17.9477C0.10498 27.5838 7.91658 35.3954 17.5527 35.3954Z" fill="#EF4130"/>
    <path d="M20.9017 9.85833H14.2075V13.3091H20.9017V9.85833Z" fill="white"/>
    <path d="M20.9017 9.85833H17.5435V13.3091H20.9017V9.85833Z" fill="white"/>
    <path d="M21.8692 15.2818H13.2358V18.7326H21.8692V15.2818Z" fill="white"/>
    <path d="M21.8697 15.2818H17.5435V18.7326H21.8697V15.2818Z" fill="white"/>
    <path d="M24.4539 20.2106H10.6509V28.8369H24.4539V20.2106Z" fill="white"/>
    <path d="M24.4547 20.2106H17.5435V28.8369H24.4547V20.2106Z" fill="white"/>
    <path d="M20.9014 13.2226H14.2073C13.04 13.7935 11.501 13.9788 9.47314 13.322C9.47314 14.5969 10.5067 15.6305 11.7816 15.6305H23.3241C24.599 15.6305 25.6326 14.5969 25.6326 13.322C23.6049 13.9788 22.0687 13.7935 20.9014 13.2226Z" fill="white"/>
    <path d="M20.9012 13.2226H17.543V15.6305H23.3239C24.5989 15.6305 25.6324 14.5969 25.6324 13.322C23.6047 13.9788 22.0685 13.7935 20.9012 13.2226Z" fill="white"/>
    <path d="M21.8691 18.4628H13.2361C11.7254 19.2016 9.72015 19.4414 7.0957 18.5914C7.0957 20.2415 8.43338 21.5789 10.0834 21.5791H25.0218C26.6719 21.5791 28.0096 20.2414 28.0096 18.5914C25.385 19.4415 23.3798 19.2017 21.8691 18.4628Z" fill="white"/>
    <path d="M21.8692 18.4628H17.543V21.5791H25.0219C26.672 21.5791 28.0096 20.2414 28.0096 18.5914C25.3852 19.4415 23.3799 19.2017 21.8692 18.4628Z" fill="white"/>
    <path d="M11.3042 9.21835C16.3984 10.8683 17.5526 5.64784 17.5526 5.64784C17.5526 5.64784 18.7066 10.8683 23.8008 9.21835C23.8008 10.2043 23.0015 11.0035 22.0156 11.0035H13.0895C12.1035 11.0035 11.3042 10.2043 11.3042 9.21835Z" fill="white"/>
    <path d="M17.5527 5.64784C17.5527 5.64784 17.5494 5.66243 17.543 5.68839V11.0035H22.0158C23.0018 11.0035 23.801 10.2042 23.801 9.21835C18.7069 10.8683 17.5527 5.64784 17.5527 5.64784Z" fill="white"/>
    <path d="M21.004 23.6614H17.5435V28.837H21.004V23.6614Z" fill="white"/>
    <path d="M21.0036 23.6614H14.1021V28.837H21.0036V23.6614Z" fill="#EF4130"/>
    </svg>
    
}